import { FC } from 'react';

import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import ExternalLinkBase from 'src/components/ExternalLink';
import ModalLayout, {
  MobileModalType,
  ModalContent,
  ModalFooter as ModalFooterBase,
  ModalHeader,
  ModalHeaderTitle,
} from 'src/components/ModalLayout';
import useHelpCenterUrl from 'src/hooks/useHelpCenterUrl';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { PunishedException } from 'src/utils/api';

const Modal = styled(ModalLayout)`
  max-width: 500px;
`;

const Desc = styled.div`
  white-space: pre-line;
  padding: 12px 0;
  font-size: 13px;
  line-height: 16px;
  min-height: 16px;
  color: ${({ theme }) => theme.color.gray600__dkGray500};
`;

const SuspensionDetailDesc = styled.div`
  margin: 16px 0;
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  padding: 16px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
  border-radius: 16px;
`;

const Row = styled.div`
  /* 서버에서 text가 \n\n 형태로 들어오는 경우, inner html이 없는 row가 생성되고, 이때 height가 0이 되어버리는 문제가 있습니다.
  의도된 개행 유지를 위해, 해당 row도 height를 가질 수 있게 처리합니다. */
  min-height: inherit;
`;

const ModalFooter = styled(ModalFooterBase)`
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  padding-top: 8px;
  gap: 8px;
  margin-bottom: 18px;
`;

const HelpCenter = styled(ExternalLinkBase)`
  text-decoration: none;
  width: 100%;
`;

const A = styled(ExternalLinkBase)`
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  text-align: center;
  text-decoration: underline;

  color: ${({ theme }) => theme.color.gray500__dkGray400};
`;

const AppealLink = styled(A)`
  width: auto;
  display: inline-block;
  color: ${({ theme }) => theme.color.white};
`;

const CommunityGuideline = styled(A)`
  color: ${({ theme }) => theme.color.gray500__dkGray400};
  font-weight: 700;
  text-decoration: none;
`;

interface SuspensionModalProps {
  punishment: PunishedException;
}

const SuspensionModal: FC<SuspensionModalProps> = ({ punishment }) => {
  const closeAllModal = useSetAtom(closeAllModalAtom);
  const contactUsButtonVisible = punishment.type === 'MATCH';
  const helpCenterUrl = useHelpCenterUrl('385068');

  const { t } = useTranslation();
  const handleClick = async () => {
    closeAllModal();
  };

  return (
    <Modal
      mobileType={MobileModalType.BOTTOM_SHEET}
      isDim
      closeWhenClickOutside={false}
    >
      <ModalHeader>
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
          {punishment.title}
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent>
        <Desc>
          {punishment.description.split('\n').map((content, index) => (
            <Row key={index}>{content}</Row>
          ))}
        </Desc>
        <SuspensionDetailDesc>
          {punishment.suspensionContent.split('\n').map((content, index) => (
            <Row key={index}>{content}</Row>
          ))}
        </SuspensionDetailDesc>
        {punishment.additionalDescription && (
          <Desc>
            {punishment.additionalDescription
              .split('\n')
              .map((content, index) => (
                <Row key={index}>{content}</Row>
              ))}
          </Desc>
        )}
        {punishment.appealUrl && (
          <AppealLink href={punishment.appealUrl}>
            {t('dsa_sor_appeal')}
          </AppealLink>
        )}
      </ModalContent>
      <ModalFooter>
        <ButtonWrap>
          {contactUsButtonVisible && (
            <HelpCenter href={helpCenterUrl}>
              <Button
                id='SUSPENSION__POPUP__BOTTOM__HELP_CENTER'
                color='tertiary'
              >
                {t('SUSPENSION__POPUP__BOTTOM__HELP_CENTER')}
              </Button>
            </HelpCenter>
          )}
          <Button id='COMMON__OK__SUSPENSION_MODAL' onClick={handleClick}>
            {t('COMMON__OK')}
          </Button>
        </ButtonWrap>
        <CommunityGuideline href={punishment.guidelineUrl}>
          {t('SUSPENSION__POPUP__BOTTOM__COMMUNITY_GUIDELINE')}
        </CommunityGuideline>
      </ModalFooter>
    </Modal>
  );
};

export default SuspensionModal;
export type { SuspensionModalProps };
