import { Platform } from 'src/types/Platform';
import { client } from 'src/utils/api';

export enum OnlineType {
  MIRROR = 'MIRROR',
  MESSAGE = 'MESSAGE',
  LOUNGE = 'LOUNGE',
  HISTORY = 'HISTORY',
  MATCH = 'MATCH',
  LIVE = 'LIVE',
  LIVE_STREAMING = 'LIVE_STREAMING',
  ABOUT = 'ABOUT',
  ETC = 'ETC',
}

const getOnlineType = () => {
  const pathname = window.location.pathname;
  if (pathname === '/') {
    return OnlineType.MIRROR;
  }
  if (pathname.includes('match')) {
    return OnlineType.MATCH;
  }
  if (pathname.includes('live')) {
    return OnlineType.LIVE;
  }
  if (pathname.includes('live/host')) {
    return OnlineType.LIVE_STREAMING;
  }

  return OnlineType.ETC;
};

export const heartbeatAPI = ({ matchId }: { matchId?: string | null }) => {
  const onlineType = getOnlineType();
  return client.post('/onair/v1/heartbeat', {
    onlineType,
    ...(onlineType === OnlineType.MATCH ? { matchId } : {}),
  });
};
export const heartbeatVisitorAPI = (uuid: string) =>
  client.post('/onair/v1/heartbeat/visitors', {
    onlineType: getOnlineType(),
    visitorUserId: uuid,
    platform: Platform.WebClient,
  });
