import { useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import 'react-mobile-datepicker-ts/dist/main.css';
import Button from 'src/components/Button';
import { ModalFooter, ModalTitle } from 'src/components/ModalLayout';
import Select from 'src/components/Select';
import {
  dateOptions,
  DropdownItem,
  monthOptions,
  yearOptions,
} from 'src/types/DateSelector';
import { hexToRgb } from 'src/utils/common';

import { BirthdayInputProps } from './type';
import { LoginModalHeader, Wrap } from 'src/components/LoginModal/styles';
import { defaultBirth } from 'src/stores/register/atoms';
import { getLocaleDatePattern } from 'src/utils/date';

const SelectWrap = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  ${({ theme }) => theme.screenSize.mobile} {
    display: none;
  }
`;

export const MobileDatePickerWrap = styled.div`
  position: relative;
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: flex;
  }
  width: 100%;
  .datepicker.ios {
    background-color: transparent;
    width: 100%;
    position: relative;
    height: auto;

    .datepicker-content {
      border-top: 1px solid ${({ theme }) => theme.color.gray200__dkGray200};
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
    }
    .datepicker-wheel {
      border-top: 1px solid
        rgba(${({ theme }) => hexToRgb(theme.color.gray200__dkGray200)}, 0.8);
      border-bottom: 1px solid
        rgba(${({ theme }) => hexToRgb(theme.color.gray200__dkGray200)}, 0.8);
    }
    .datepicker-viewport::after {
      ${({
        theme: {
          color: { gray900 },
        },
      }) => `
          background: linear-gradient(${gray900}, rgb(${hexToRgb(
            gray900
          )}, 0) 52%, rgba(${hexToRgb(gray900)}, 0) 48%,${gray900});
        `}
    }
    .datepicker-scroll li {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

const StyledSelect = styled(Select)`
  flex: 1;
  .react-select__menu {
    height: 400% !important;
  }
` as typeof Select;

export const DropdownBirthdayInput = ({ onSubmit }: BirthdayInputProps) => {
  const [birth, setBirth] = useState<Date>(defaultBirth);
  const [isBirthChanged, setIsBirthChanged] = useState(false);
  const { t } = useTranslation();
  const datePatternRef = useRef(getLocaleDatePattern());

  const handleChangeYear = (v: DropdownItem | null) => {
    if (!v) {
      return;
    }
    setIsBirthChanged(true);
    const nextBirth = new Date(birth || '');
    nextBirth.setFullYear(parseInt(v.value, 10));
    setBirth(nextBirth);
  };
  const handleChangeMonth = (v: DropdownItem | null) => {
    if (!v) {
      return;
    }
    setIsBirthChanged(true);
    const nextBirth = new Date(birth || '');
    nextBirth.setMonth(parseInt(v.value, 10));
    setBirth(nextBirth);
  };
  const handleChangeDate = (v: DropdownItem | null) => {
    if (!v) {
      return;
    }
    setIsBirthChanged(true);
    const nextBirth = new Date(birth || '');
    nextBirth.setDate(parseInt(v.value, 10));
    setBirth(nextBirth);
  };

  return (
    <>
      <Wrap>
        <LoginModalHeader>
          <ModalTitle>{t('SIGNUP__INFO_BIRTHDAY')}</ModalTitle>
        </LoginModalHeader>
        <SelectWrap>
          {datePatternRef.current.map((pattern) => {
            switch (pattern) {
              case 'MM':
                return (
                  <StyledSelect
                    absolute
                    label={t('month')}
                    menuPlacement='bottom'
                    isSearchable={false}
                    options={monthOptions}
                    onChange={handleChangeMonth}
                    value={monthOptions.find(
                      ({ value }) => value === `${birth.getMonth()}`
                    )}
                  />
                );
              case 'DD':
                return (
                  <StyledSelect
                    absolute
                    label={t('date')}
                    menuPlacement='bottom'
                    isSearchable={false}
                    options={dateOptions}
                    value={dateOptions.find(
                      ({ value }) => value === `${birth.getDate()}`
                    )}
                    onChange={handleChangeDate}
                  />
                );
              case 'YEAR':
                return (
                  <StyledSelect
                    absolute
                    label={t('year')}
                    menuPlacement='bottom'
                    isSearchable={false}
                    options={yearOptions}
                    onChange={handleChangeYear}
                    data-testid='RegisterStep2-Select-year'
                    value={yearOptions.find(
                      ({ value }) => value === `${birth.getFullYear()}`
                    )}
                  />
                );
            }
          })}
        </SelectWrap>
      </Wrap>
      <ModalFooter>
        <Button
          onClick={() => onSubmit(birth)}
          disabled={!isBirthChanged}
          id='SIGNUP__NEXT_REGISTER_BIRTHDAY_STEP'
          data-testid='RegisterBirthdayStep-button-next'
        >
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>
  );
};
