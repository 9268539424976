import { FC, PropsWithChildren } from 'react';
import Button, { ButtonProps as BaseButtonProps } from 'src/components/Button';
import {
  ButtonWrap,
  Container,
  Description,
  DescriptionContainer,
  LinkText,
  Title,
} from './styles';

type ButtonProps = Omit<BaseButtonProps, 'id'> & { text: string };

type Props = {
  eventId: string;
  title: string;
  description?: string;
  linkText?: string;
  link?: string;
  mainButtonProps: ButtonProps;
  subButtonProps?: ButtonProps;
};

const Template: FC<PropsWithChildren<Props>> = ({
  eventId,
  title,
  description,
  linkText,
  link,
  children,
  mainButtonProps,
  subButtonProps,
}) => {
  return (
    <Container>
      <DescriptionContainer>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {children}
      </DescriptionContainer>
      <ButtonWrap>
        <Button
          {...mainButtonProps}
          id={`sms-login-${eventId}`}
          color={mainButtonProps.color ?? 'secondary'}
        >
          {mainButtonProps.text}
        </Button>
        {subButtonProps && (
          <Button
            {...subButtonProps}
            id={`sms-login-${eventId}-sub`}
            color={subButtonProps.color ?? 'tertiary'}
          >
            {subButtonProps.text}
          </Button>
        )}
        {linkText && <LinkText href={link}>{linkText}</LinkText>}
      </ButtonWrap>
    </Container>
  );
};

export default Template;
export type { Props };
