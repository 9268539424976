import * as Sentry from '@sentry/nextjs';
import { atom } from 'jotai';
import { createRef } from 'react';

import { eventMutateAtom } from 'src/stores/event/atoms';
import {
  eventMatchPageAtom,
  handleDecoStreamVideoAtom,
  handleStreamVideoAtom,
  matchInfoAtom,
  statusAtom,
} from 'src/stores/match/atoms';
import { openedModalsAtom, showModalAtom } from 'src/stores/modal/atoms';
import { showToastAtom } from 'src/stores/toast/atoms';
import {
  DecoGroupName,
  DecoUnsupportReason,
  DecoEffect,
  DecoEffectGroup,
  EFFECT_NONE,
  UserEffectSetting,
  ClientEffect,
} from 'src/types/Deco';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { ToastType } from 'src/types/Toast';
import { getResourceWithCache } from 'src/utils/cache';
import { getAtomWithStorage } from 'src/utils/localStorageUtils';
import getDeviceInfo from 'src/utils/device/info';

import { getDecoEffectListAPI, reportInMatchEffectUsageAPI } from './api';
import EffectRendererPipe from 'src/utils/media/pipes/effect';
import WebToAppModal from 'src/components/WebToAppModal';
import { WebToAppModalType } from 'src/types/WebToApp';
import { getRandomElement } from 'src/utils/array';

export const isDecoSupportedAtom = atom(true);
export const isRandomEffectAtom = atom(false); // 현재 적용중인 이펙트가 랜덤 이펙트에 의해 트리거되었는지 여부

export const isShowDecoRedDotAtom = getAtomWithStorage<boolean>(
  'isShowDecoRedDot',
  true
);

/**
 * 이펙트 기능 자체에 대한 코치마크
 */
export const isShowEffectStudioCoachMarkAtom = getAtomWithStorage<boolean>(
  'isShowDecoCoachMark',
  true
);

/**
 * 새롭게 출시된 특정 이펙트에 대한 코치마크
 */
export const isShowNewEffectCoachMarkAtom = getAtomWithStorage(
  // 대상이 되는 이펙트마다 새롭게 노출될 수 있도록 키값 변경
  'isShowGestureEffectCoachMark',
  true
);

export const decoVidRefAtom = atom(createRef<HTMLCanvasElement>());

const baseActiveEffectsAtom = getAtomWithStorage<DecoEffect[]>(
  'activeEffects',
  [EFFECT_NONE]
);

/**
 * 한 번이라도 제스쳐 이펙트를 활성화한 적용이 있는지 여부 (실제 트리거와는 무관)
 * 제스쳐 이펙트의 자동 적용 여부 결정 판단 위해서 사용
 */
const hasAppliedGestureEffectAtom = getAtomWithStorage('appliedGesture', false);

/**
 * 이펙트가 적용되지 않은 경우 [EFFECT_NONE] 요소 하나가 존재해야하기 때문에
 * atom composing으로 방어 로직 포함된 setter function 적용
 */
export const activeEffectsAtom = atom(
  (get) => get(baseActiveEffectsAtom),
  (get, set, update: DecoEffect[] | ((prev: DecoEffect[]) => DecoEffect[])) => {
    const updatedActiveEffects =
      typeof update === 'function'
        ? update(get(baseActiveEffectsAtom))
        : update;

    set(
      baseActiveEffectsAtom,
      updatedActiveEffects.length ? updatedActiveEffects : [EFFECT_NONE]
    );
  }
);

export const isApplyingEffectAtom = atom((get) => {
  const activeEffects = get(activeEffectsAtom);
  // 이펙트가 적용되지 않았을 때에도 [EFFECT_NONE]의 상태라 발생 가능하지 않지만 방어 로직으로 작성
  if (!activeEffects.length) {
    Sentry.captureMessage(
      'Active effect is empty while it should have more than one element'
    );
    return false;
  }
  return (
    activeEffects.length > 1 ||
    activeEffects[0].effectId !== EFFECT_NONE.effectId
  );
});

export const userEffectSettingAtom = atom<UserEffectSetting>({
  effectIdList: [],
  decoEffectStrengths: [],
  facialRecognitionValue: 0,
});

export const isLoadingEffectRendererPipeAtom = atom(false);

export const isShowToolbarAtom = atom(true);
export const loadingEffectIdSetAtom = atom<Set<DecoEffect['effectId']>>(
  new Set<DecoEffect['effectId']>()
);

const markEffectAsLoadingAtom = atom(null, (get, set, effect: DecoEffect) => {
  set(loadingEffectIdSetAtom, (prev) => new Set(prev.add(effect.effectId)));
});

const unMarkEffectAsLoadingAtom = atom(null, (get, set, effect: DecoEffect) => {
  set(loadingEffectIdSetAtom, (prev) => {
    const newSet = new Set(prev);
    newSet.delete(effect.effectId);
    return newSet;
  });
});

/**
 * 이펙트 관련 최소 하나의 요소가 로딩중임을 의미
 */
export const isLoadingEffectAtom = atom(
  (get) =>
    get(isLoadingEffectRendererPipeAtom) || get(loadingEffectIdSetAtom).size > 0
);

export const decoListAtom = atom<DecoEffectGroup[]>([]);

export const effectsAtom = atom<DecoEffect[]>((get) => {
  return get(decoListAtom)
    .map((effectGroup) => effectGroup.effects)
    .flat();
});

/**
 * key: effectId
 */
export const effectGroupMapAtom = atom<Map<string, DecoGroupName>>(new Map());

export const effectIdsAtom = getAtomWithStorage<DecoEffect['effectId'][]>(
  'effectIds',
  []
);

export const sendGestureEffectTriggerEventAtom = atom(
  null,
  (get, set, effect: DecoEffect) => {
    const matchInfo = get(matchInfoAtom);
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.DECO,
      eventName: EVENT_NAME.MIRROR__REPORT_OPERATE_GESTURE,
      eventParams: {
        action_category: 'report',
        tab: 'mirror',
        page: get(eventMatchPageAtom),
        target: 'operate_gesture',
        effect_id: effect.effectId,
        match_id: matchInfo?.matchId ?? null,
      },
    });
  }
);

export const sendGestureEffectToggleEventAtom = atom(
  null,
  (
    get,
    set,
    effect: DecoEffect,
    isToggledOn: boolean,
    reason: 'click' | 'othereffect' | 'removeeffect' | 'default'
  ) => {
    const matchInfo = get(matchInfoAtom);
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.DECO,
      eventName: EVENT_NAME.MIRROR__REPORT_GESTURE_TOGGLE,
      eventParams: {
        action_category: 'report',
        tab: 'mirror',
        page: get(eventMatchPageAtom),
        target: 'gesture_toggle',
        reason: reason,
        effect_id: effect.effectId,
        status: isToggledOn ? 'on' : 'off',
        match_id: matchInfo?.matchId ?? null,
      },
    });
  }
);

export const handleDecoEffectLoadCompleteAtom = atom(
  null,
  (get, set, effect: DecoEffect) => {
    set(unMarkEffectAsLoadingAtom, effect);

    const activeEffects = get(activeEffectsAtom);
    const loadedActiveEffects = activeEffects.filter((activeEffect) => {
      return !get(loadingEffectIdSetAtom).has(activeEffect.effectId);
    });

    set(eventMutateAtom, {
      eventType: EVENT_TYPE.DECO,
      eventName: EVENT_NAME.MIRROR_REPORT_SAVE_EFFECT,
      eventParams: {
        action_category: 'report',
        tab: 'mirror',
        page: get(eventMatchPageAtom),
        target: 'save_effect',
        save_effect: loadedActiveEffects.map((activeEffect) => ({
          effect_id: activeEffect.effectId,
        })),
      },
    });

    const userEffectSetting: UserEffectSetting = {
      effectIdList: loadedActiveEffects.map(
        (activeEffect) => activeEffect.effectId
      ),
      decoEffectStrengths: [],
      facialRecognitionValue: 0,
    };
    set(userEffectSettingAtom, { ...userEffectSetting });
    const matchId = get(matchInfoAtom)?.matchId;
    if (get(statusAtom) === STATUS.MATCHED && matchId) {
      reportInMatchEffectUsageAPI(matchId, { ...userEffectSetting });
    }
  }
);

const activateEffectAtom = atom(null, (get, set, effect: DecoEffect) => {
  const activeEffects = get(activeEffectsAtom);

  if (
    activeEffects.some(
      (activeEffect) => activeEffect.effectId === effect.effectId
    )
  )
    return;

  const effectGroupMap = get(effectGroupMapAtom);
  const effectGroupName = effectGroupMap.get(effect.effectId);
  if (!effectGroupName) return;

  // 다음에 또 제스쳐 이펙트 자동 적용하지 않도록 기록
  if (effectGroupName === DecoGroupName.GESTURE) {
    set(hasAppliedGestureEffectAtom, true);
  }

  let updatedActiveEffects: DecoEffect[] = [effect];

  const EFFECT_GROUPS_COMPATIBLE_WITH_GESTURE = [
    DecoGroupName.GESTURE,
    DecoGroupName.BACKGROUND,
    DecoGroupName.BEAUTY,
  ];

  if (effectGroupName === DecoGroupName.GESTURE) {
    const isCompatibleWithPrevEffects = activeEffects.every((activeEffect) => {
      const activeEffectGroupName = effectGroupMap.get(activeEffect.effectId);
      if (!activeEffectGroupName) return;

      return EFFECT_GROUPS_COMPATIBLE_WITH_GESTURE.includes(
        activeEffectGroupName
      );
    });
    if (isCompatibleWithPrevEffects) {
      updatedActiveEffects = [...updatedActiveEffects, ...activeEffects];
    }
    // 새롭게 활성화한 이펙트가 제스쳐 이펙트와 호환되는 경우 기존 제스쳐 이펙트는 활성화 유지
  } else if (EFFECT_GROUPS_COMPATIBLE_WITH_GESTURE.includes(effectGroupName)) {
    const prevActiveGestureEffects = activeEffects.filter((activeEffect) => {
      const activeEffectGroupName = effectGroupMap.get(activeEffect.effectId);
      if (!activeEffectGroupName) return;

      return activeEffectGroupName === DecoGroupName.GESTURE;
    });

    updatedActiveEffects = [
      ...updatedActiveEffects,
      ...prevActiveGestureEffects,
    ];
  }

  const deactivatedEffects = activeEffects.filter((activeEffect) => {
    return updatedActiveEffects.every(
      (updatedActiveEffect) =>
        updatedActiveEffect.effectId !== activeEffect.effectId
    );
  });

  const isGestureEffectDeactivated = deactivatedEffects.reduce(
    (prev, deactivatedEffect) => {
      EffectRendererPipe.removeEffect(deactivatedEffect);
      const isGestureEffect =
        effectGroupMap.get(deactivatedEffect.effectId) ===
        DecoGroupName.GESTURE;

      if (isGestureEffect) {
        set(
          sendGestureEffectToggleEventAtom,
          deactivatedEffect,
          false,
          effect.effectId === EFFECT_NONE.effectId
            ? 'removeeffect'
            : 'othereffect'
        );
      }

      return prev || isGestureEffect;
    },
    false
  );

  /**
   * 다른 이펙트 on으로 인해 제스쳐 이펙트가 자동으로 꺼진 경우
   * 제스쳐 이펙트는 중첩이 가능한 경우도 있기 떄문에 유저가 예측 불가
   * -> 확실한 인지 위해 별도 안내 토스트 제공
   */
  if (isGestureEffectDeactivated && effect.effectId !== EFFECT_NONE.effectId) {
    set(showToastAtom, {
      type: ToastType.INFO,
      message: 'deco_studio_info_gesture_removed',
    });
  }

  set(activeEffectsAtom, updatedActiveEffects);

  updatedActiveEffects.forEach((updatedActiveEffect) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    set(handleDecoDownloadEffectAtom, updatedActiveEffect);
  });
});

export const deactivateEffectAtom = atom(
  null,
  (get, set, effect: DecoEffect) => {
    const activeEffects = get(activeEffectsAtom);

    const updatedActiveEffects = activeEffects.filter(
      (activeEffect) => activeEffect.effectId !== effect.effectId
    );

    if (updatedActiveEffects.length !== activeEffects.length) {
      EffectRendererPipe.removeEffect(effect);
      set(activeEffectsAtom, updatedActiveEffects);
    }
  }
);

export const selectDecoEffectAtom = atom(
  null,
  // boolean 타입지정 삭제시 default값으로 타입 유추 안 되고 unknown으로 잡혀서 타입 에러 발생
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  (get, set, effect: DecoEffect, isRandom: boolean = false) => {
    set(isRandomEffectAtom, isRandom);

    set(activateEffectAtom, effect);
  }
);

export const removeDecoEffectAtom = atom(null, (get, set) => {
  set(userEffectSettingAtom, {
    effectIdList: [],
    decoEffectStrengths: [],
    facialRecognitionValue: 0,
  });

  set(loadingEffectIdSetAtom, new Set());
  set(selectDecoEffectAtom, EFFECT_NONE);
});

export const handleUnsupportedDecoAccessAtom = atom(null, (get, set) => {
  const { isMobile } = getDeviceInfo();

  if (isMobile) {
    set(showModalAtom, {
      key: ModalType.WEB_TO_APP,
      component: () => <WebToAppModal type={WebToAppModalType.EFFECT} />,
    });
  } else {
    set(showToastAtom, {
      type: ToastType.ERROR,
      message: 'deco_studio_error_not_supported',
    });
  }
});

export const handleDecoUnsupportAtom = atom(
  null,
  (get, set, unsupportReason?: DecoUnsupportReason) => {
    const decoWasPreviouslyAvailable = get(isDecoSupportedAtom);

    set(isDecoSupportedAtom, false);

    // 이펙트가 처음으로 비활성화 되었을 때에만 이벤트 로그 전송
    if (decoWasPreviouslyAvailable && unsupportReason !== undefined) {
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.DECO,
        eventName: EVENT_NAME.MIRROR_REPORT_FAIL_EFFECT,
        eventParams: {
          action_category: 'report',
          tab: 'mirror',
          page: get(eventMatchPageAtom),
          target: 'fail_effect',
          reason: unsupportReason,
        },
      });
    }

    const isDecoModalOpen = get(openedModalsAtom).some(
      (modal) => modal.key === ModalType.DECO_STUDIO
    );

    // 데코 스튜디오 모달이 켜져 있거나 현재 적용 혹은 시도중인 이펙트가 있는 경우
    if (isDecoModalOpen || get(isApplyingEffectAtom)) {
      set(showToastAtom, {
        type: ToastType.ERROR,
        message: 'deco_studio_error_not_supported',
      });
    }

    set(removeDecoEffectAtom);
  }
);

/**
 * 이펙트를 적용하는 과정에서 예상치 못한 이슈가 발생하여 실제로 해당 이펙트가 유저에게 적용되지 못하는 경우
 */
export const handleUnexpectedEffectFailAtom = atom(
  null,
  (get, set, failedEffect: DecoEffect) => {
    set(unMarkEffectAsLoadingAtom, failedEffect);
    set(showToastAtom, {
      type: ToastType.ERROR,
      message: 'common__problem_occurred',
    });

    set(activeEffectsAtom, (prev) =>
      prev.filter(
        (activeEffect) => activeEffect.effectId !== failedEffect.effectId
      )
    );
  }
);

export const handleDecoDownloadEffectAtom = atom(
  null,
  async (get, set, effect: DecoEffect) => {
    if (!get(isDecoSupportedAtom) || !get(decoListAtom).length) return;

    if (!effect.attributes?.effectRenderer) return;

    set(markEffectAsLoadingAtom, effect);

    let activeResource: ArrayBuffer | null = null;

    if (effect.resource?.resourceUrl) {
      let encryptedResource;
      try {
        encryptedResource = await getResourceWithCache(
          effect.resource.resourceUrl,
          'arraybuffer'
        );
      } catch {
        set(showToastAtom, {
          type: ToastType.ERROR,
          message: 'deco_studio_error_network',
        });
        set(unMarkEffectAsLoadingAtom, effect);
        set(eventMutateAtom, {
          eventType: EVENT_TYPE.DECO,
          eventName: EVENT_NAME.MIRROR_REPORT_NETWORK_FAIL_EFFECT,
          eventParams: {
            action_category: 'report',
            tab: 'mirror',
            page: get(eventMatchPageAtom),
            target: 'network_fail_effect',
          },
        });
        return;
      }

      activeResource = (
        await import(
          /* webpackChunkName: 'resourceDecryption' */ 'src/utils/resourceDecryption'
        )
      ).resourceDecrypt(
        encryptedResource,
        effect.resource?.encryptionKey || ''
      ).buffer;
    }

    // 로딩 도중에 active effect가 변경되지 않았을 때에만 실제 스트림에 반영
    const isActive = get(activeEffectsAtom).some(
      (activeEffect) => activeEffect.effectId === effect.effectId
    );
    if (!isActive) return;

    EffectRendererPipe.setEffect(effect, activeResource).then((result) => {
      if (result === true) {
        set(handleDecoEffectLoadCompleteAtom, effect);
      } else if (result === false) {
        set(handleUnexpectedEffectFailAtom, effect);
      }
    });
    set(handleDecoStreamVideoAtom);
    set(handleStreamVideoAtom);
    // 추가적인 로딩 작업이 필요하지 않는 경우
    if (!effect.resource) {
      set(handleDecoEffectLoadCompleteAtom, effect);
    }
  }
);

export const loadEffectRendererPipeAtom = atom(null, async (get, set) => {
  if (get(isLoadingEffectRendererPipeAtom) || !get(isDecoSupportedAtom)) return;

  set(isLoadingEffectRendererPipeAtom, true);
  const isInitSuccess = await EffectRendererPipe.initialize();

  if (!isInitSuccess) {
    /**
     * 이펙트 라이브러리 내부에서 미디어파이프 모델을 구동함에 따라 어느 모델이 실패했는지 구분 불가능해짐
     * 이벤트 로그 제외 에러 핸들링이 동일하고 두 모델의 구동 실패 환경이 크게 차이나지 않으므로 임시로 일관되게 처리
     * @see https://hpcnt.slack.com/archives/C02SQS5AJDR/p1726733760517309
     *
     * @TODO 이펙트 라이브러리에 에러 인터페이스 추가시 재검토
     */
    set(
      handleDecoUnsupportAtom,
      DecoUnsupportReason.MEDIAPIPE_LOAD_FAILED_SELFIE_SEGMENTATION
    );
  }

  set(isLoadingEffectRendererPipeAtom, false);
});

/**
 * 특정 트리거 포인트에서 다른 이펙트 적용중이지 않은 상태일 때 자동으로 제스쳐 이펙트 활성화 (1회 한정)
 * 제스쳐 이펙트 사용률 높이는 것이 목적
 */
export const autoActivateGestureEffectsAtom = atom(null, async (get, set) => {
  if (get(isApplyingEffectAtom) || get(hasAppliedGestureEffectAtom)) return;

  const effectGroups = get(decoListAtom);
  const gestureEffects = effectGroups.find(
    (effectGroup) => effectGroup.groupId === DecoGroupName.GESTURE
  )?.effects;

  if (!gestureEffects?.length) return;

  gestureEffects.forEach((gestureEffect) => {
    set(sendGestureEffectToggleEventAtom, gestureEffect, true, 'default');
    set(activateEffectAtom, gestureEffect);
  });
});

export const getDecoEffectListAtom = atom(null, async (get, set) => {
  const {
    data: {
      result: { effectGroups },
    },
  } = await getDecoEffectListAPI();

  // 서버에서 내려주는 이펙트 중 클라이언트에서 인지하는 목록만 필터링
  const supportedEffectGroups = effectGroups.filter((effectGroup) => {
    return Object.values(DecoGroupName).includes(effectGroup.groupId);
  });

  const effects = supportedEffectGroups
    .map((effectGroup) => effectGroup.effects)
    .flat();

  // 캐싱된 기존 이펙트가 deprecated되지 않았는지 검사
  if (get(isApplyingEffectAtom)) {
    const cachedEffects = get(activeEffectsAtom);

    const updatedActiveEffects = cachedEffects
      .map((cachedEffect) => {
        return effects.find(
          // 세부 설정 등 바뀌었을 경우 고려해서 동일 effect id여도 API로 가져온 것으로 적용
          (effect) => effect.effectId === cachedEffect.effectId
        );
      })
      // deprecated effect (호환성 이슈 등에 의한 크래시 방지)
      .filter(
        (cachedEffect): cachedEffect is DecoEffect => cachedEffect !== undefined
      );

    set(activeEffectsAtom, updatedActiveEffects);
  }

  // update effect group map
  const effectGroupMap = new Map();
  effectGroupMap.set(ClientEffect.NONE, DecoGroupName.ETC);
  supportedEffectGroups.forEach((effectGroup) => {
    effectGroup.effects.forEach((effect) => {
      effectGroupMap.set(effect.effectId, effectGroup.groupId);
    });
  });
  set(effectGroupMapAtom, effectGroupMap);

  // 신규 이펙트 추가 여부 기준으로 레드닷 노출 여부 결정
  const updatedEffectIds = effects.map((effect) => effect.effectId);
  const prevEffectIds = get(effectIdsAtom);
  const prevEffectIdSet = new Set(prevEffectIds);

  if (
    updatedEffectIds.some(
      (updatedEffectId) => !prevEffectIdSet.has(updatedEffectId)
    )
  ) {
    set(isShowDecoRedDotAtom, true);
  }

  set(effectIdsAtom, updatedEffectIds);

  set(decoListAtom, supportedEffectGroups);

  if (!getDeviceInfo().isMobile) {
    set(autoActivateGestureEffectsAtom);
  }

  get(activeEffectsAtom).forEach((activeEffect) => {
    set(handleDecoDownloadEffectAtom, activeEffect);
  });
});

export const applyRandomEffectAtom = atom(null, (get, set) => {
  const decoList = get(decoListAtom);

  // 전략적으로 집중하는 이펙트 그룹 기준으로 모집단 선정
  const candidateGroup = decoList.find(
    (effect) => effect.groupId === DecoGroupName.STYLE
  );

  if (!candidateGroup) return;

  const activeEffectIdSet = new Set(
    get(activeEffectsAtom).map((activeEffect) => activeEffect.effectId)
  );

  const candidateEffects = candidateGroup.effects.filter((effect) => {
    // 현재 적용중인 이펙트는 제외
    const isActive = activeEffectIdSet.has(effect.effectId);
    return !isActive;
  });

  const randomEffectToApply = getRandomElement(candidateEffects);

  set(selectDecoEffectAtom, randomEffectToApply, true);

  return randomEffectToApply;
});
