export enum ReportType {
  VISUAL_ABUSE = 'VISUAL_ABUSE',
  VERBAL_ABUSE = 'VERBAL_ABUSE',
  CHILD_AGE_ABUSE = 'CHILD_AGE_ABUSE',
  VIDEO_PROFILE_ABUSE = 'VIDEO_PROFILE_ABUSE',
  FAKE_GENDER_ABUSE = 'FAKE_GENDER_ABUSE',
  DSA_ILLEGAL_CONTENT = 'DSA_ILLEGAL_CONTENT',
}

export enum DSAContentType {
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  NICKNAME = 'NICKNAME',
  BIO = 'BIO',
}

export enum DSACategoryType {
  DSA_CATEGORY_ANIMAL_WELFARE = 'DSA_CATEGORY_ANIMAL_WELFARE',
  DSA_CATEGORY_DATA_PROTECTION_AND_PRIVACY_VIOLATIONS = 'DSA_CATEGORY_DATA_PROTECTION_AND_PRIVACY_VIOLATIONS',
  DSA_CATEGORY_ILLEGAL_OR_HARMFUL_SPEECH = 'DSA_CATEGORY_ILLEGAL_OR_HARMFUL_SPEECH',
  DSA_CATEGORY_INTELLECTUAL_PROPERTY_INFRINGEMENTS = 'DSA_CATEGORY_INTELLECTUAL_PROPERTY_INFRINGEMENTS',
  DSA_CATEGORY_NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS = 'DSA_CATEGORY_NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS',
  DSA_CATEGORY_NON_CONSENSUAL_BEHAVIOUR = 'DSA_CATEGORY_NON_CONSENSUAL_BEHAVIOUR',
  DSA_CATEGORY_ONLINE_BULLYING_INTIMIDATION = 'DSA_CATEGORY_ONLINE_BULLYING_INTIMIDATION',
  DSA_CATEGORY_PORNOGRAPHY_OR_SEXUALIZED_CONTENT = 'DSA_CATEGORY_PORNOGRAPHY_OR_SEXUALIZED_CONTENT',
  DSA_CATEGORY_PROTECTION_OF_MINORS = 'DSA_CATEGORY_PROTECTION_OF_MINORS',
  DSA_CATEGORY_RISK_FOR_PUBLIC_SECURITY = 'DSA_CATEGORY_RISK_FOR_PUBLIC_SECURITY',
  DSA_CATEGORY_SCAMS_AND_OR_FRAUD = 'DSA_CATEGORY_SCAMS_AND_OR_FRAUD',
  DSA_CATEGORY_SELF_HARM = 'DSA_CATEGORY_SELF_HARM',
  DSA_CATEGORY_SCOPE_OF_PLATFORM_SERVICE = 'DSA_CATEGORY_SCOPE_OF_PLATFORM_SERVICE',
  DSA_CATEGORY_UNSAFE_AND_OR_ILLEGAL_PRODUCTS = 'DSA_CATEGORY_UNSAFE_AND_OR_ILLEGAL_PRODUCTS',
  DSA_CATEGORY_VIOLENCE = 'DSA_CATEGORY_VIOLENCE',
  DSA_CATEGORY_OTHER = 'DSA_CATEGORY_OTHER',
}

export interface DSAContext {
  userName?: string;
  userEmail?: string;
  contentType: Omit<DSAContentType, 'BIO'>;
  contentLocation: string;
  reason: string;
  profileImageId?: string;
  dsaCategory: DSACategoryType;
}

export interface ReportProfileCardRequest {
  cardType: ReportProfileCardType;
  cardId: string;
  reportType: string;
  dsaContext?: Partial<DSAContext>;
}

/**
 * 
 * 웹에서는 아래 type들은 사용하지 않음
  // MY_PROFILE = 'MY_PROFILE',
  FRIEND = 'FRIEND',
  OFFICIAL_ACCOUNT = 'OFFICIAL_ACCOUNT',
  // PEER_ASYNC = 'PEER_ASYNC',
  // MY_ASYNC = 'MY_ASYNC',
  LOUNGE_RECOMMEND = 'LOUNGE_RECOMMEND',
  LOUNGE_LIKE = 'LOUNGE_LIKE',
  TODAY_LOUNGE_RECOMMEND = 'TODAY_LOUNGE_RECOMMEND',
  HISTORY = 'HISTORY',
  AZAR_PICK = 'AZAR_PICK',
  PROFILE_VIEWER = 'PROFILE_VIEWER',
 */
export enum ReportProfileCardType {
  MATCH = 'MATCH',
  HISTORY_CARD = 'HISTORY_CARD',
}

export interface ReportEventParam {
  action_category: string;
  tab: string;
  page: string;
  target: string;
  target_id: string;
  target_detail: string | undefined;
}
