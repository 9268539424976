import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;

// sentry release name >= 40 이면 소스맵 업로드 에러남
// vX.X 또는 vX.X.X인 버전은 slice하지 않고 그대로 사용
const versionRegex = /^v\d+\.\d+(\.\d+)?$/;
const RELEASE = process.env.NEXT_PUBLIC_RELEASE
  ? process.env.NEXT_PUBLIC_RELEASE.split('-')
      .map((el) => (versionRegex.test(el) ? el : el.slice(0, 7)))
      .join('-')
  : '';
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_LOGGING_EVENT_LEVEL = (process.env
  .NEXT_PUBLIC_SENTRY_LOGGING_EVENT_LEVEL || 'warning') as Sentry.SeverityLevel;
const SENTRY_SAMPLE_RATE = Number(process.env.SENTRY_SAMPLE_RATE) || 1;
const SENTRY_TRACES_SAMPLE_RATE =
  ENVIRONMENT === 'Prod'
    ? Number(process.env.HPCNT_SENTRY_TRACES_SAMPLE_RATE) || 0.02
    : Number(process.env.HPCNT_SENTRY_DEV_TRACES_SAMPLE_RATE) || 0.05;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || ENVIRONMENT;

const ignoreErrors = [
  // Not a bug
  'Abort route change', // 페이지 이탈방지위해 일부러 throw하는 에러
  'The user denied permission to use Service Worker.', // 크롬에서 service worker 미허용
  // known issue - netowrk
  'Request aborted', // axios
  'encountered a network error', // chrome) network error
  'NetworkError when attempting to fetch resource.', // firefox) network error
  'Load failed', // ios) netowrk error
  // known issue - permission
  'CacheStorage', // chrome) cache storage permission
  'SecurityError', // ios) service worker permission
  'The operation is insecure.', // firefox) cache & service worker permission bug.
  // known issue - etc
  'requestCode', // social login
  'https://azarlive.com/sw-prod-v', // 배포로 인해 기존버젼 service worker 파일이 s3상에서 사라진 경우
  'ChunkLoadError', // 배포로 인해 기존에 돌아가던 어플리케이션이 chunk를 못 찾는 이슈
  'Loading chunk 202 failed', // antman load failed
  // known issue - vendor
  "undefined is not an object (evaluating 'r.DomainData')", // onetrust) vendor 내부 이슈 - safari
  "null is not an object (evaluating 'this.selector.appendChild')", // onetrust) vendor 내부 이슈 - safari
  "Cannot read properties of undefined (reading 'Domain')", // onetrust) vendor 내부 이슈 - chrome
  "Cannot read properties of null (reading 'document')", // chrome) cloudflare 이슈
  'webkit-masked-url', // safari) 확장프로그램 내 이슈
  'this._perf.domInteractive', // tiktok) vendor 내부 이슈
  "(evaluating 'a.N')", // gtm) vendor 내부 이슈 추정
  // azar server
  'Failed to fetch',
  'Network Error',
  'Request failed with',
  'timeout',
  // matata
  'Not Permission',
  'The user has already left the live',
  'This channel is already subscribed',
  'SDKServerError',
  'UnhandledRejection',
  "Failed to execute 'querySelectorAll' on 'Document': 'div:has(> iframe[id='198230182308109283091823098102938908128390'])' is not a valid selector.",
];

if (process.env.NEXT_PUBLIC_DEBUG === 'true' || ENVIRONMENT === 'Prod') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: RELEASE,
    sampleRate: SENTRY_SAMPLE_RATE,
    integrations: [
      new Integrations.BrowserTracing(),
      new ExtraErrorDataIntegration({
        // limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: 10,
      }),
    ],
    beforeBreadcrumb: (breadcrumb, hint) => {
      // make xhr category verbose
      if (breadcrumb.category === 'xhr' && hint?.xhr) {
        // filter responses
        return {
          type: breadcrumb.type,
          level: breadcrumb.level,
          category: breadcrumb.category,
          data: {
            ...breadcrumb.data,
            response: hint.xhr.response,
            method: hint.xhr.__sentry_xhr__.method,
            url: hint.xhr.__sentry_xhr__.url,
            statusCode: hint.xhr.__sentry_xhr__.status_code,
            statusText: hint.xhr.statusText,
            body: hint.xhr.__sentry_xhr__.body,
          },
        } as Sentry.Breadcrumb;
      }
      return breadcrumb;
    },
    beforeSend(event, hint) {
      const err = hint.originalException;
      const check = (key: 'name' | 'message') => {
        const value = err instanceof Error ? err[key] : err;
        if (
          typeof value === 'string' &&
          ignoreErrors.some((error) => value.indexOf(error) >= 0)
        ) {
          return true;
        }
      };
      if (check('name') || check('message')) {
        return null;
      }
      return event;
    },
    ignoreErrors,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    normalizeDepth: 10,
    maxBreadcrumbs: 200,
    maxValueLength: 300,
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /extension\//i,
      /chrome-extension\//i,
      /^chrome:\/\//i,
    ],
    debug: ENVIRONMENT !== 'Prod',
  });
  Sentry.configureScope((scope) => scope.setLevel(SENTRY_LOGGING_EVENT_LEVEL));
}
