import { atom } from 'jotai';
import QueryString from 'qs';

import GemLackModal from 'src/components/GemLackModal';
import { showModalAtom } from 'src/stores/modal/atoms';
import {
  PAYMENT_POPUP_INFO_KEY,
  paymentPopupInfoAtom,
} from 'src/stores/payments/atoms';
import { UTMParamsAtom } from 'src/stores/utm/atoms';
import { ModalType } from 'src/types/Modal';
import { PaymentPopupInfo } from 'src/types/Payments';

import { getShopEntryBannerAPI } from './apis';
import { ShopEntryBanner } from './types';

export const paymentPath = atom((get) => {
  const UTMParams = get(UTMParamsAtom);
  const UTMParamsStringified = QueryString.stringify(UTMParams, {
    addQueryPrefix: true,
  });
  const url = '/payment/' + UTMParamsStringified;
  return url;
});

const paymentPopupAtom = atom<WindowProxy | null>(null);

export const showPaymentPopupAtom = atom(
  null,
  async (get, set, item: PaymentPopupInfo) => {
    set(paymentPopupInfoAtom, item);
    const openedPopup = get(paymentPopupAtom);
    if (openedPopup) {
      openedPopup.sessionStorage?.setItem(
        PAYMENT_POPUP_INFO_KEY,
        JSON.stringify(item)
      );
    }
    const url = get(paymentPath);

    const popupWidth = 650;
    const popupHeight = 650;
    const screenLeft = window.screenLeft;
    const screenTop = window.screenTop;

    const left =
      (screenLeft >= 0 ? 1 : -1) * (screen.width / 2) - popupWidth / 2;
    const top =
      (screenTop >= 0 ? 1 : -1) * (screen.height / 2) - popupHeight / 2;

    const popup = window.open(
      url,
      'popup',
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );
    set(paymentPopupAtom, popup);
  }
);

export const closePaymentPopupAtom = atom(null, (get, set) => {
  const paymentPopup = get(paymentPopupAtom);
  if (paymentPopup && !paymentPopup.closed) {
    paymentPopup.close();
  }
  set(paymentPopupAtom, null);
});

export const shopEntryBannerAtom = atom<null | ShopEntryBanner>(null);

export const getShopEntryBannerAtom = atom(null, async (_get, set) => {
  try {
    const { data } = await getShopEntryBannerAPI();
    set(shopEntryBannerAtom, data);
  } catch (_e) {
    // setting 없음
  }
});

export const shopEntryBannerShowAtom = atom(true);
export const shopEntryBannerClickedAtom = atom(false);
export const repeatedCountAtom = atom(1);

type CheckGemSufficientParams = {
  lackingAmount: number;
  description: string;
};
export const showGemLackModalAtom = atom(
  null,
  (
    _get,
    set,
    { lackingAmount: lackingAmount, description }: CheckGemSufficientParams
  ) => {
    if (lackingAmount <= 0) return;
    set(showModalAtom, {
      key: ModalType.GEM_LACK,
      component: () =>
        GemLackModal({ lackingGemAmount: lackingAmount, description }),
    });
  }
);
