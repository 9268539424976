import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import 'react-mobile-datepicker-ts/dist/main.css';
import LoadingIndicator from 'src/components/LoadingIndicator';
import useEvent from 'src/hooks/useEvent';
import { socialTokenAtom, tryLoginTypeAtom } from 'src/stores/auth/atoms';
import {
  birthAtom,
  nextStepAtom,
  signUpSettingsLoadableAtom,
} from 'src/stores/register/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';

import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { BirthdayUIType } from 'src/types/register';
import { calculateAge } from 'src/utils/date';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { DropdownBirthdayInput } from 'src/components/LoginModal/BirthdayInput/DropdownBirthdayInput';
import { ScrollBirthdayInput } from 'src/components/LoginModal/BirthdayInput/ScrollBirthdayInput';
import { MobileBirthdayInputTypeA } from 'src/components/LoginModal/BirthdayInput/MobileBirthdayInputTypeA';
import { AgeConfirmModal } from 'src/components/LoginModal/AgeConfirmModal';

const LoadingBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const BIRTHDAY_UI_TYPE_MAP_MOBILE: Record<BirthdayUIType, FC<any>> = {
  BIRTHDAY_WEB_A: MobileBirthdayInputTypeA,
  BIRTHDAY_WEB_B: ScrollBirthdayInput,
};

const BIRTHDAY_UI_TYPE_MAP_DESKTOP: Record<BirthdayUIType, FC<any>> = {
  BIRTHDAY_WEB_A: DropdownBirthdayInput,
  BIRTHDAY_WEB_B: ScrollBirthdayInput,
};

const RegisterBirthdayStep: FC = () => {
  const setBirth = useSetAtom(birthAtom);
  const [socialToken, setSocialToken] = useAtom(socialTokenAtom);
  const nextStep = useSetAtom(nextStepAtom);
  const device = useCssMediaDevice();
  const isMobile = device === 'mobile';
  const signUpSettingsLoadable = useAtomValue(signUpSettingsLoadableAtom);
  const signUpSettings =
    signUpSettingsLoadable.state === 'hasData'
      ? signUpSettingsLoadable.data
      : null;

  const pushEvent = useEvent();
  const tryLoginType = useAtomValue(tryLoginTypeAtom);
  const showModal = useSetAtom(showModalAtom);

  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPEN__LOGIN_BIRTHDAY_INFO,
      eventParams: { loginType: tryLoginType },
    });
  }, [pushEvent, tryLoginType]);

  useEffect(() => {
    if (socialToken?.birth) {
      setBirth(socialToken.birth);
      setSocialToken({ ...socialToken, birth: undefined });
      nextStep();
    }
  }, [nextStep, socialToken, setBirth, setSocialToken]);

  const handleSubmit = (date: Date) => {
    const blockAlertAge = signUpSettings?.birthdaySettings.blockAlertAge;
    const age = calculateAge(date);
    if (blockAlertAge && age <= blockAlertAge) {
      showModal({
        key: ModalType.INVALID_AGE_CHECK,
        component: () => (
          <AgeConfirmModal userAge={age} limitedAge={blockAlertAge} />
        ),
      });
      return;
    }
    setBirth(date);
    nextStep();
  };

  const BirthdayInput = isMobile
    ? BIRTHDAY_UI_TYPE_MAP_MOBILE[
        signUpSettings?.birthdaySettings.uiType || 'BIRTHDAY_WEB_B'
      ]
    : BIRTHDAY_UI_TYPE_MAP_DESKTOP[
        signUpSettings?.birthdaySettings.uiType || 'BIRTHDAY_WEB_B'
      ];

  return socialToken?.birth || signUpSettingsLoadable.state === 'loading' ? (
    <LoadingBackground>
      <LoadingIndicator size={18} />
    </LoadingBackground>
  ) : (
    <BirthdayInput onSubmit={handleSubmit} />
  );
};

export default RegisterBirthdayStep;
