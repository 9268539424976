import { atom } from 'jotai';
import { exhaustMap } from 'rxjs/operators';

import { userDataAtom } from 'src/stores/auth/atoms';
import { atomWithPipes } from 'src/utils/atom';
import { uploadContentManager } from 'src/utils/content-manager';
import { readFileSync } from 'src/utils/io';
import {
  getAtomWithStorage,
  reviveDateFromStorage,
} from 'src/utils/localStorageUtils';

import {
  deleteProfileImageAPI,
  getHashtagsAPI,
  getProfileAPI,
  orderProfileImagesAPI,
  updateMainProfileImageAPI,
  uploadProfileImageAPI,
} from './apis';
import { UserProfile } from './types';

export const lastGetProfileTimeAtom = getAtomWithStorage<Date | null>(
  'lastGetProfileTime',
  null,
  {
    reviver: reviveDateFromStorage,
  }
);

export const profileAtom = atom<UserProfile | undefined>(undefined);

export const hashtagsAtom = atom<{ id: number; hashtag: string }[] | null>(
  null
);

const handleProfileDataAtom = atom(
  null,
  async (get, set, profileData: Partial<UserProfile>) => {
    const profile = get(profileAtom);
    if (profile) {
      set(profileAtom, {
        ...profile,
        ...profileData,
      });
    }
  }
);

export const getHashtagsAtom = atom(null, async (get, set) => {
  const userId = get(userDataAtom)?.userId;
  if (!userId) {
    return;
  }

  try {
    const { data } = await getHashtagsAPI();
    set(hashtagsAtom, data);
  } catch {
    set(hashtagsAtom, null);
  }
});

export const getMyProfileAtom = atom(null, async (get, set) => {
  const userId = get(userDataAtom)?.userId;
  if (!userId) {
    return;
  }

  try {
    const { data } = await getProfileAPI(userId);
    set(lastGetProfileTimeAtom, new Date());
    set(profileAtom, data);
  } catch {
    set(profileAtom, undefined);
  }
});

export const deleteProfileImageAtom = atomWithPipes<null, [number]>(
  null,
  async (get, set, profileImageId) => {
    const { data } = await deleteProfileImageAPI(profileImageId);
    set(handleProfileDataAtom, { profileImageInfos: data });
  },
  [exhaustMap]
);

export const orderProfileImagesAtom = atom(
  null,
  async (get, set, profileImageInfos: UserProfile['profileImageInfos']) => {
    const orders = profileImageInfos.map(({ id }) => id);
    set(handleProfileDataAtom, { profileImageInfos });
    const { data } = await orderProfileImagesAPI({ orders });
    set(handleProfileDataAtom, { profileImageInfos: data });
  }
);

export const uploadProfileImageAtom = atom(
  null,
  async (get, set, file: Blob) => {
    const convertedFile = await readFileSync(file);
    const fileInfo = await uploadContentManager(convertedFile, 'image/jpeg');
    const { data: profileImageInfos } = await uploadProfileImageAPI(fileInfo);
    set(handleProfileDataAtom, { profileImageInfos });
  }
);

export const updateMainProfileImageAtom = atom(
  null,
  async (get, set, file: Blob) => {
    const convertedFile = await readFileSync(file);
    const fileInfo = await uploadContentManager(convertedFile, 'image/jpeg');
    const { data: profileImageInfos } =
      await updateMainProfileImageAPI(fileInfo);
    set(handleProfileDataAtom, { profileImageInfos });
  }
);
