import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import ChildGenderFilterInput from 'src/components/ChildGenderFilterInput';
import Gap from 'src/components/Gap';
import LoadingIndicator from 'src/components/LoadingIndicator';
import {
  ModalContent as ModalContentBase,
  ModalFooter as ModalFooterBase,
  ModalLoading,
  ModalTitle,
} from 'src/components/ModalLayout';
import ModalFooter, { FooterDim } from 'src/components/ModalLayout/ModalFooter';
import useEvent from 'src/hooks/useEvent';
import {
  handleAgeRestrictedLoginAttemptAtom,
  socialTokenAtom,
  tryLoginTypeAtom,
} from 'src/stores/auth/atoms';
import {
  birthAtom,
  FALLBACK_SIGNUP_SETTINGS,
  registerAtom,
  signUpSettingsLoadableAtom,
} from 'src/stores/register/atoms';
import { UserSettingType } from 'src/stores/user/types';
import { visitorConfigAtom } from 'src/stores/visitor/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { GENDER, NonBinaryChildGender } from 'src/types/register';
import { checkAgeOverN } from 'src/utils/date';

import {
  GenderButton,
  GenderDesc,
  LoadingBackground,
  LoginModalHeader,
  SelectChildGenderButton,
} from 'src/components/LoginModal/styles';
import { useGetConsents } from 'src/hooks/useGetConsents';

const ArrowRight = styled(
  dynamic(() => import('src/assets/images/icChevronRight.svg'))
)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.color.gray600__dkGray500};
  ${({ theme }) => theme.isRtl && 'transform: scaleX(-1);'}
`;

const NonBinaryText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildGenderText = styled.span`
  color: ${({ theme }) => theme.color.gray600__dkGray500};
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
`;

const ModalContent = styled(ModalContentBase)`
  position: relative;
`;

const ChildGenderFilterInputWrap = styled(ModalContent)`
  padding: 16px 0;
`;

const ChildGenderFilterInputFooter = styled(ModalFooterBase)`
  // 스크롤 가능할 시 FooterDim 보여주기 위해
  padding: 0;
  ${FooterDim} {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
`;

const RegisterGenderStep: React.FC = () => {
  const birth = useAtomValue(birthAtom);
  const handleRegister = useSetAtom(registerAtom);

  const signUpSettingsLoadable = useAtomValue(signUpSettingsLoadableAtom);
  const signUpSettings =
    signUpSettingsLoadable.state === 'hasData'
      ? signUpSettingsLoadable.data
      : FALLBACK_SIGNUP_SETTINGS;

  const { data: consentsData } = useGetConsents();
  const { uiType: consentsUiType } = consentsData?.data?.result || {};

  const [isShowChildGenderFilterInput, setIsShowChildGenderFilterInput] =
    useState(false);
  const [selectedChildGender, setSelectedChildGender] =
    useState<NonBinaryChildGender>();
  const [selectedGender, setSelectedGender] = useState<GENDER>();
  const [hideGenderOption, setHideGenderOption] = useState<boolean | null>(
    null
  );
  const useMinAge = useAtomValue(visitorConfigAtom)?.webRegMinAge || 19;
  const handleAgeRestrictedLoginAttempt = useSetAtom(
    handleAgeRestrictedLoginAttemptAtom
  );

  useEffect(() => {
    setHideGenderOption(signUpSettings.genderSettings.hideGenderDefaultValue);
  }, [signUpSettings.genderSettings.hideGenderDefaultValue]);

  const { t } = useTranslation();

  const pushEvent = useEvent();
  const tryLoginType = useAtomValue(tryLoginTypeAtom);

  useEffect(() => {
    if (checkAgeOverN(birth, useMinAge)) {
      pushEvent({
        eventType: EVENT_TYPE.SIGN_UP,
        eventName: EVENT_NAME.SCREEN_OPEN__LOGIN_GENDER_INFO,
        eventParams: { loginType: tryLoginType },
      });
      return;
    }
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPEN__LOGIN_AGE_RESTRICTED,
      eventParams: { loginType: tryLoginType },
    });
    handleAgeRestrictedLoginAttempt();
  }, [birth, pushEvent, tryLoginType, useMinAge]);

  const [socialToken, setSocialToken] = useAtom(socialTokenAtom);
  useEffect(() => {
    if (!socialToken?.gender || hideGenderOption === null) return;
    const gender = socialToken?.gender;
    setSocialToken({ ...socialToken, gender: undefined });
    handleRegister({
      gender,
      featureSettings: [
        { type: UserSettingType.HIDE_GENDER, value: hideGenderOption },
      ],
      cmpConsentsAccepted: consentsUiType === 'CONSENT_WEB_A',
    });
  }, [
    consentsUiType,
    handleRegister,
    hideGenderOption,
    setSocialToken,
    socialToken,
  ]);

  useEffect(() => {
    if (selectedChildGender) {
      setSelectedGender(GENDER.NONBINARY);
    }
  }, [selectedChildGender]);

  const onClickNext = useCallback(() => {
    if (hideGenderOption === null) return;
    const target =
      selectedGender === GENDER.NONBINARY
        ? 'non_binary'
        : selectedGender?.toLowerCase();
    pushEvent({
      eventName: EVENT_NAME.SIGNUP__SELECT_GENDER,
      eventType: EVENT_TYPE.SIGN_UP,
      eventParams: {
        action_category: 'action',
        tab: 'signup',
        page: 'gender',
        target,
        child_gender: selectedChildGender?.key || target,
      },
    });
    handleRegister({
      gender: selectedGender,
      childGender: selectedChildGender?.key,
      featureSettings: [
        { type: UserSettingType.HIDE_GENDER, value: hideGenderOption },
      ],
      cmpConsentsAccepted: consentsUiType === 'CONSENT_WEB_A',
    });
  }, [
    consentsUiType,
    handleRegister,
    hideGenderOption,
    pushEvent,
    selectedChildGender?.key,
    selectedGender,
  ]);

  const handleClickBinary = useCallback((gender: GENDER) => {
    setSelectedChildGender(undefined);
    setSelectedGender(gender);
  }, []);

  const handleClickNonBinary = useCallback(() => {
    if (selectedGender !== GENDER.NONBINARY) {
      setSelectedGender(GENDER.NONBINARY);
      return;
    }
    setIsShowChildGenderFilterInput(true);
  }, [selectedGender]);

  useEffect(() => {
    if (selectedGender === GENDER.FEMALE || selectedGender === GENDER.MALE) {
      onClickNext();
    }
  }, [onClickNext, selectedGender]);

  const disabedNext = useMemo(() => {
    if (!selectedGender) return true;
    if (selectedGender === GENDER.NONBINARY && !selectedChildGender)
      return true;
    return false;
  }, [selectedChildGender, selectedGender]);

  if (!checkAgeOverN(birth, useMinAge)) {
    return null;
  }

  if (socialToken?.gender || hideGenderOption === null) {
    return (
      <LoadingBackground>
        <LoadingIndicator size={18} />
      </LoadingBackground>
    );
  }

  if (isShowChildGenderFilterInput) {
    return (
      <>
        <ChildGenderFilterInputWrap>
          <ChildGenderFilterInput
            list={signUpSettings.genderSettings.nonBinaryChildGenders}
            setSelectedChildGender={setSelectedChildGender}
            close={() => setIsShowChildGenderFilterInput(false)}
          />
        </ChildGenderFilterInputWrap>
        <ChildGenderFilterInputFooter />
      </>
    );
  }

  return (
    <>
      <ModalContent>
        <LoginModalHeader>
          <ModalTitle>{t('SIGNUP__INFO_GENDER')} </ModalTitle>
        </LoginModalHeader>
        {signUpSettingsLoadable.state !== 'loading' && (
          <>
            <GenderButton
              onClick={() => handleClickBinary(GENDER.MALE)}
              inactive={!!selectedGender && selectedGender !== GENDER.MALE}
            >
              {t('gender_male')}
            </GenderButton>
            <GenderButton
              onClick={() => handleClickBinary(GENDER.FEMALE)}
              inactive={!!selectedGender && selectedGender !== GENDER.FEMALE}
            >
              {t('gender_female')}
            </GenderButton>
            {signUpSettings.genderSettings.nonBinaryEnabled && (
              <GenderButton
                onClick={handleClickNonBinary}
                inactive={
                  !!selectedGender && selectedGender !== GENDER.NONBINARY
                }
              >
                <NonBinaryText>
                  {t('gender_nonbinary')}
                  {selectedGender === GENDER.NONBINARY && (
                    <SelectChildGenderButton>
                      {selectedChildGender ? (
                        <ChildGenderText>
                          {selectedChildGender.localizedText}
                        </ChildGenderText>
                      ) : (
                        t('signup_gender_nonbinary_desc')
                      )}
                      <Gap width={8} />
                      <ArrowRight />
                    </SelectChildGenderButton>
                  )}
                </NonBinaryText>
              </GenderButton>
            )}
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          id='sign-up-gender-next'
          disabled={disabedNext}
          onClick={onClickNext}
        >
          {t('SIGNUP__NEXT')}
        </Button>
        <GenderDesc>{t('signup_extra_info_gender_warning')}</GenderDesc>
      </ModalFooter>
    </>
  );
};

const WithSuspense: React.FC = () => (
  <React.Suspense fallback={<ModalLoading />}>
    <RegisterGenderStep />
  </React.Suspense>
);

export default React.memo(WithSuspense);
