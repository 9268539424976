import styled from '@emotion/styled';

const EmailFormText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const EmailFormInformation = styled(EmailFormText)`
  margin-top: 16px;
  color: ${({ theme }) => theme.color.gray500__dkGray400};
`;

export const EmailStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 365px;
  padding: 24px 0px;

  ${({ theme }) => theme.screenSize.mobile} {
    height: calc(100% - 58px);
  }

  .email-valid-code-input {
    flex-basis: 100%;
    height: 56px;
  }
`;

export const ErrorMessageWrap = styled.div`
  margin-top: 16px;
`;
