import { differenceInMilliseconds } from 'date-fns';
import { atom } from 'jotai';

import {
  iceServersAtom,
  newStompBrokerInfoAtom,
  removeTokenAtom,
  userDataAtom,
} from 'src/stores/auth/atoms';
import { isAxiosError } from 'src/utils/error';
import { getAtomWithStorage } from 'src/utils/localStorageUtils';

import {
  getFeatureSettingsAPI,
  getUserSegmentAPI,
  setFeatureSettingsAPI,
} from './api';
import { FeatureSettings, SetFeatureSettingsParam } from './types';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import SuspensionModal from 'src/components/SuspensionModal';
import { ErrorResponse, PunishedException } from 'src/utils/api';

export const featureSettingsAtom = atom<
  FeatureSettings['featureSettings'] | null
>(null);

export const getFeatureSettingsAtom = atom(null, (_get, set) => {
  getFeatureSettingsAPI()
    .then(({ data }) => {
      set(featureSettingsAtom, data.result.featureSettings);
    })
    .catch(() => {});
});

export const setFeatureSettingsAtom = atom(
  null,
  async (get, set, params: SetFeatureSettingsParam['featureSettings']) => {
    await setFeatureSettingsAPI({ featureSettings: params });
    const featureSettings = get(featureSettingsAtom);

    const newSettings = featureSettings ? [...featureSettings] : null;
    if (newSettings) {
      for (const param of params) {
        for (const setting of newSettings) {
          if (setting.name === param.type) {
            setting.value = param.value;
            break;
          }
        }
      }
      set(featureSettingsAtom, newSettings);
    }
  }
);

const DEVICE_ID_KEY = 'deviceId';
export const deviceIdAtom = getAtomWithStorage<string | void>(
  DEVICE_ID_KEY,
  undefined
);

export const lastGetSegmentTimeAtom = getAtomWithStorage<Date | null>(
  'lastGetSegmentTime',
  null
);

const DEFAULT_REFRESH_USER_INFO_MIN_INTERVAL_SEC = 10; // 10초

export const getUserSegmentAtom = atom(null, async (get, set) => {
  const userData = get(userDataAtom);
  if (!userData) return;
  const lastGetSegmentTime = get(lastGetSegmentTimeAtom);
  if (
    // 새로고침 했을 때 마지막으로 세그먼트를 가져온 시간 기준 일정 시간이 지나지 않았다면 세그먼트 다시 가져오지 않음
    differenceInMilliseconds(new Date(), new Date(lastGetSegmentTime ?? 0)) <
    (userData.remoteConfig.refreshUserInfoMinIntervalInSeconds ??
      DEFAULT_REFRESH_USER_INFO_MIN_INTERVAL_SEC) *
      1000
  )
    return;
  try {
    const {
      data: { result },
    } = await getUserSegmentAPI();
    set(lastGetSegmentTimeAtom, new Date());
    set(
      userDataAtom,
      (prevUserData) =>
        prevUserData && {
          ...prevUserData,
          remoteConfig: result.remoteConfig,
          featureOptions: result.featureOptions,
          pushChannelName: result.pushChannelName,
        }
    );
    if (!window.AzarJs) {
      // iceServers 웹뷰에서는 안씀, stompBrokerInfo는 웹뷰에서 우회주소를 GetServersInfoAPI에서 가져오므로 무시
      set(iceServersAtom, result.iceServers);
      set(newStompBrokerInfoAtom, result.stompBrokerInfo);
    }
  } catch (err) {
    if (!isAxiosError<ErrorResponse<PunishedException>>(err)) return;
    const code = err?.response?.data.error?.code;
    const errorBody = err?.response?.data.error?.data;
    if (!code || !errorBody) return;
    switch (code) {
      case 'PUNISHED':
        set(showModalAtom, {
          key: ModalType.LOGIN_SUSPENSION,
          component: () =>
            SuspensionModal({
              punishment: errorBody,
            }),
        });
        set(removeTokenAtom);
        break;
      case 'UNAUTHORIZED':
        set(removeTokenAtom);
        break;
      default:
        throw err;
    }
  }
});
