import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'src/components/Input';
import EmailAddressRegisterStep from './EmailAddressRegisterStep';
import Template from './Template';
import { ErrorMessageWrap } from 'src/components/MoreModal/EmailStep/style';
import VerifyCodeInput from 'src/components/VerifyCode';
import useDeviceTransferSendCode from 'src/hooks/useDeviceTransferSendCode';
import useDeviceTransferVerifyCode from 'src/hooks/useDeviceTransferVerifyCode';
import useTimer from 'src/hooks/useTimer';
import { nextStepAtom } from 'src/stores/register/atoms';
import {
  addStepsOnPhoneNumberLoginStepsAtom,
  emailVerificationAtom,
  isRemoveOldDeviceUserDetailsAtom,
  PhoneNumberRegisterSteps,
} from 'src/stores/phoneNumberLogin/atoms';
import { VERIFY_CODE_LENGTH } from 'src/stores/phoneNumberLogin/types';
import { TimerStatus } from 'src/types/timer';
import { VERIFICATION_CODE_RESEND_BLOCK_TIMEOUT_WITH_BUFFER } from 'src/components/LoginModal/constants';

const ResendButton = styled.button`
  color: ${({ theme }) => theme.color.gray300__dkGray250};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration-line: underline;
  background-color: transparent;
`;
const CodeInputWrap = styled.div`
  padding-top: 24px;
`;
const Time = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray300__dkGray250};
`;
const EmailVerificationVerifyCodeStep: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorText, setErrorText] = useState('');
  const [code, setCode] = useState('');
  const { sendCode, translatedErrorMessage: sendCodeErrorMessage } =
    useDeviceTransferSendCode();
  const {
    handleSend: handleVerifyCode,
    translatedErrorMessage: verifyCodeErrorMessage,
    failureCount,
    isLoading: isVerifyingCode,
  } = useDeviceTransferVerifyCode();
  const handleChangeVerifyCode = useCallback(
    (value: string) => {
      if (value.length <= VERIFY_CODE_LENGTH) {
        setErrorText('');
        setCode(value);
      }
      if (value.length === VERIFY_CODE_LENGTH) {
        handleVerifyCode(value);
      }
    },
    [handleVerifyCode, setCode, setErrorText]
  );
  useEffect(() => {
    setErrorText((verifyCodeErrorMessage || sendCodeErrorMessage) ?? '');
  }, [failureCount, sendCodeErrorMessage, verifyCodeErrorMessage]);

  const { status, time, start, reset } = useTimer({
    endTime: VERIFICATION_CODE_RESEND_BLOCK_TIMEOUT_WITH_BUFFER,
    increment: false,
    onTimeOver: () => {},
  });
  useEffect(() => {
    start();
  }, [start]);
  const setRemoveOldDeviceUserDetails = useSetAtom(
    isRemoveOldDeviceUserDetailsAtom
  );
  const addSteps = useSetAtom(addStepsOnPhoneNumberLoginStepsAtom);
  const goNextStep = useSetAtom(nextStepAtom);

  // 기존 PhoneNumber 계정 삭제하고, 동일 PhoneNumber로 새 계정 생성
  const handleSubClick = () => {
    setRemoveOldDeviceUserDetails(true);
    addSteps(EmailAddressRegisterStep, ...PhoneNumberRegisterSteps);
    goNextStep();
  };
  const { email } = useAtomValue(emailVerificationAtom);

  const { t } = useTranslation();
  const Retry = useCallback(() => {
    if (errorText) {
      return (
        <ErrorMessageWrap>
          <ErrorMessage message={errorText} />
        </ErrorMessageWrap>
      );
    }
    if (TimerStatus.START === status) {
      return (
        <Time>
          {t('device_transfer__resend_code_delay', {
            time: `${time.minute}:${time.second}`,
          })}
        </Time>
      );
    }
    return (
      <ResendButton
        onClick={() => {
          sendCode();
          reset();
        }}
      >
        {t('more__resend_code')}
      </ResendButton>
    );
  }, [errorText, sendCode, reset, status, t, time.minute, time.second]);

  return (
    <Template
      eventId='signup_phone_verified_title'
      title={t('signup_phone_verified_title')}
      description={t('device_transfer__send_code', { email })}
      mainButtonProps={{
        text: t('signup_phone_btn_next'),
        loading: isVerifyingCode,
        disabled: code.length !== VERIFY_CODE_LENGTH || !!errorText,
        onClick: () => {
          handleVerifyCode(code);
        },
      }}
      subButtonProps={{
        text: t('signup_phone_btn_reg'),
        onClick: handleSubClick,
        disabled: isVerifyingCode,
      }}
    >
      <CodeInputWrap>
        <VerifyCodeInput
          isError={!!errorText}
          code={code}
          onChange={handleChangeVerifyCode}
          numOfArray={VERIFY_CODE_LENGTH}
          inputRef={inputRef}
          isEditable={!isVerifyingCode}
        />
        <Retry />
      </CodeInputWrap>
    </Template>
  );
};

export default React.memo(EmailVerificationVerifyCodeStep);
