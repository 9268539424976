import { MatchEmailRequest, MatchEmailResponse } from './types';
import { createApiCall } from 'src/utils/api';

export const PhoneNumberLoginApiList = {
  VerifyEmail: '/api/v2/user-emails/verifications/validate',
  PhoneVerificationPreparation: 'api/v1/phones/verifications/preparation',
  VerificationsSend: '/api/v1/phones/verifications/send',
  VerificationsValidate: '/api/v1/phones/verifications/validate',
  VerificationsPreparation: '/api/v1/phones/verifications/preparation',
  CheckAccountExist: (id: string) => {
    return `/api/v1/phones/${id}`;
  },
  DeviceTransferSendCode: '/api/v1/accounts/phone/device-transfer/send-code',
  DeviceTransferVerifyCode:
    '/api/v1/accounts/phone/device-transfer/verify-code',
  DeviceTransferMatchEmail:
    '/api/v1/accounts/phone/device-transfer/match-email',
};

export const checkAccountExistAPI = (phoneId: string) =>
  createApiCall<void, boolean>('get', `/api/v1/phones/${phoneId}`);

export const matchEmailAPI = createApiCall<
  MatchEmailRequest,
  MatchEmailResponse
>('post', PhoneNumberLoginApiList.DeviceTransferMatchEmail);
