import { useAtomValue } from 'jotai';
import * as Sentry from '@sentry/nextjs';

import { useRestMutation } from 'src/hooks/useRestMutation';
import { PhoneNumberLoginApiList } from 'src/stores/phoneNumberLogin/apis';
import { smsVerificationResponseAtom } from 'src/stores/phoneNumberLogin/atoms';
import {
  DeviceTransferSendCode,
  DeviceTransferSendCodeErrorCode,
  deviceTransferSendCodeErrorCodeTranslationMap,
} from 'src/stores/phoneNumberLogin/types';

import { ErrorResponse } from 'src/utils/api';
import useErrorMessageTranslation from 'src/hooks/useErrorMessageTranslation';

export default function useDeviceTransferSendCode() {
  const { isLoading, isSuccess, isError, error, mutateAsync } = useRestMutation<
    DeviceTransferSendCode,
    void,
    ErrorResponse<null, DeviceTransferSendCodeErrorCode>
  >({
    path: PhoneNumberLoginApiList.DeviceTransferSendCode,
    method: 'post',
  });

  const smsVerificationResponse = useAtomValue(smsVerificationResponseAtom);
  const sendCode = async () => {
    if (!smsVerificationResponse) {
      Sentry.captureMessage('sendCode called without smsVerificationResponse', {
        extra: {
          hook: 'useDeviceTransferSendCode',
        },
      });
      return;
    }
    const { phoneId, phoneToken } = smsVerificationResponse;
    await mutateAsync({
      phoneId,
      phoneToken,
    });
  };

  const translatedErrorMessage = useErrorMessageTranslation(
    deviceTransferSendCodeErrorCodeTranslationMap,
    'SIGNUP_PHONE_VERIFICATION_FAIL',
    error?.response?.data.error
  );

  return {
    sendCode,
    isLoading,
    isSuccess,
    isError,
    translatedErrorMessage,
  };
}
