import { FC, useCallback } from 'react';

import styled from '@emotion/styled';

import LoadingIndicatorBase from 'src/components/LoadingIndicator';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import getDeviceInfo from 'src/utils/device/info';

import { ButtonColor, ButtonStatus, ButtonVariant, ColorMap } from './types';
import { mouseHoverCss } from 'src/utils/styled';

const ChildrenWrap = styled.div<{ loading: boolean }>`
  ${({ loading }) =>
    loading &&
    `
    opacity: 0;
  `}
`;

const buttonStatusArr: ButtonStatus[] = [
  '&',
  '&:hover',
  '&:active',
  '&:disabled',
];

const StyledButton = styled.button<{
  color: ButtonColor;
  variant: ButtonVariant;
  message?: string;
  isMobile?: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ variant }) => (variant === 'text' ? '2px' : '0px')} 24px;
  font-size: 15px;
  font-weight: 700;
  height: ${({ variant }) => (variant === 'text' ? 'auto' : '52px')};

  ${({ color, variant, isMobile }) =>
    Object.entries(ColorMap)
      .map(([key, colorMap]) =>
        buttonStatusArr
          .map((status) => {
            if (status === '&:hover' && isMobile) {
              return;
            }
            const value = colorMap[status]?.[variant]?.[color];
            if (!value) {
              return;
            }
            if (status === '&') {
              return `${key}: ${value};`;
            } else {
              return `${status} { ${key}: ${value}; }`;
            }
          })
          .filter((css) => css)
          .join('\n')
      )
      .join('\n')}

  ${({ variant }) =>
    variant === 'outlined' &&
    `
    border-width: 1px;
    border-style: solid;
  `}

  border-radius: 28px;
  transition: 0.2s background-color ease;
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
  ${({ message, theme }) =>
    message &&
    `
    &:after {
      visibility: hidden;
      content: "${message}";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      padding: 8px 12px;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: ${theme.color.white};
      background: rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      opacity: 0;
      width: max-content;
      transition: opacity .3s, bottom .3s;
    }
    ${mouseHoverCss(`
      &:after {
        visibility: visible;
        bottom: calc(100% + 4px);
        opacity: 1;
      }
    `)}
  `}
`;

const LoadingIndicator = styled(LoadingIndicatorBase)`
  color: ${({ theme }) => theme.color.dkGray250};
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  color?: ButtonColor;
  variant?: ButtonVariant;
  loading?: boolean;
  id: string;
  message?: string;
}

const Button: FC<ButtonProps> = ({
  disabled = false,
  color = 'primary',
  variant = 'contained',
  onClick,
  loading = false,
  className,
  children,
  id,
  message,
  ...props
}) => {
  const { isMobile } = getDeviceInfo();
  const pushEvent = useEvent();
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (loading || disabled) {
        // 유저의 dom 조작을 통한 어뷰징 방지
        return;
      }
      if (onClick) {
        pushEvent({
          eventName: `${id}_${EVENT_NAME.CLICK_BUTTON}`,
          eventType: EVENT_TYPE.BUTTON,
        });
        return onClick(e);
      }
    },
    [loading, disabled, onClick, pushEvent, id]
  );
  return (
    <StyledButton
      isMobile={isMobile}
      className={className}
      disabled={disabled || loading}
      color={color}
      variant={variant}
      onClick={handleClick}
      {...props}
      message={message}
    >
      {loading ? (
        <>
          <LoadingIndicator size={24} />
          <ChildrenWrap loading={loading}>{children}</ChildrenWrap>
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
export type { ButtonProps };
