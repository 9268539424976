import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';

import { ErrorResponse } from 'src/utils/api';

const useErrorMessageTranslation = <ErrorCode extends string>(
  errorCodeTranslationMap: Partial<Record<ErrorCode, string>>,
  fallbackErrorTranslationKey: string,
  error?: ErrorResponse<unknown, ErrorCode>['error']
) => {
  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    if (!error) return;

    const errorTranslationKey = errorCodeTranslationMap[error.code];

    if (!errorTranslationKey) {
      Sentry.captureMessage(`Failed translate Errorcode: ${error.code}`);
    }

    return t(errorTranslationKey ?? fallbackErrorTranslationKey);
  }, [error, errorCodeTranslationMap, fallbackErrorTranslationKey, t]);

  return errorMessage;
};

export default useErrorMessageTranslation;
