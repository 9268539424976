import {
  FC,
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';

import LoadingIndicator from 'src/components/LoadingIndicator';
import {
  ModalBackground,
  ModalKeyContext,
  Props as ModalProps,
} from 'src/components/Modal';
import {
  ModalContentContext,
  ModalContentProvider,
} from 'src/context/ModalContext/ModalContentContext';
import useIntersection from 'src/hooks/useIntersection';
import { closeModalAtom, openedModalsAtom } from 'src/stores/modal/atoms';
import { fadeInTop } from 'src/styles/animation';
import { hexToRgb } from 'src/utils/common';

import { ModalScrollRoot } from './ModalScrollComponent';
import { ModalNavButtonType } from 'src/types/Modal';

export const enum MobileModalType {
  BOTTOM_SHEET = 'bottomSheet',
  SCREEN_MODAL = 'screenModal',
}

const screenModal = css`
  flex-wrap: wrap;
  border-radius: 0px;
  overflow: scroll;
  flex-direction: column;
  width: var(--100vw);
  height: 100%;
  bottom: 0;
  position: absolute;
`;

export const ModalInner = styled.div<{ mobileType: MobileModalType }>`
  width: 100%;
  height: 100%;
  ${({ mobileType }) =>
    mobileType === MobileModalType.BOTTOM_SHEET &&
    `
    max-height: 80vh;
    @supports (max-height: 80dvh) {
     max-height: 80dvh;
    }
  `}
  display: flex;
  flex-direction: column;
`;

export const bottomModal = css`
  width: var(--100vw);
  position: fixed;
  bottom: 0px;
  animation: ${fadeInTop} 0.4s ease-in-out;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;
export const ModalRootStyle = styled.div<{
  mobileType: MobileModalType;
}>`
  border-radius: 20px;
  --background-color: ${({ theme }) => theme.color.white__dkBlack};
  background-color: var(--background-color);
  min-width: 400px;
  position: relative;
  box-shadow: 0px 2px 20px
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0.2);
  max-width: 700px;
  bottom: 0;
  ${({ theme }) => theme.screenSize.tablet} {
    max-width: 500px;
  }
  ${({ theme }) => theme.screenSize.mobile} {
    ${({ mobileType }) =>
      mobileType === MobileModalType.BOTTOM_SHEET ? bottomModal : screenModal}
    max-width: 100%;
    min-width: auto;
  }
`;

const ButtonIcon = styled.img`
  ${({ theme }) =>
    theme.isRtl &&
    `
    transform: scale(-1);
  `}
`;
export const Button = styled.button`
  position: absolute;
  background-color: transparent;
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  width: 40px;
  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 16px;
  top: 16px;
  padding: 8px;
  cursor: pointer;
`;
const CloseButton = styled(Button)<{ mobileType: MobileModalType }>`
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: ${({ mobileType }) =>
      mobileType === MobileModalType.SCREEN_MODAL ? 'flex' : 'none'};
  }
`;
// max-height 주어서 컨텐츠 높이조절()
export const ModalContentStyle = styled(ModalScrollRoot)`
  padding-left: 24px;
  padding-right: 24px;
  max-width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  resize: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const ModalTitleContainer = styled.div`
  z-index: 1;
  padding: 24px 24px;
  gap: 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const ModalTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.white};
  margin: 0px;
  line-height: 40px;
`;
export const ModalTitleDescription = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray500__dkGray400};
`;
export const ModalDim = (isShow: boolean) => css`
  position: absolute;
  pointer-events: none;
  width: 100%;
  left: 0px;
  transition: opacity 0.1s;
  opacity: ${isShow ? 1 : 0};
  z-index: 1;
`;

export const ModalHeaderTitle = styled.p<{ mobileType: MobileModalType }>`
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  word-break: keep-all;
  word-wrap: break-word;
  ${({ theme }) => theme.screenSize.mobile} {
    ${({ mobileType, theme }) =>
      mobileType === MobileModalType.SCREEN_MODAL &&
      (theme.isRtl ? 'margin-right' : 'margin-left') + ': 32px;'}
  }
`;
export const ModalHeaderDescriptionRow = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray600__dkGray500};
  line-height: 18px;
`;
type ModalLoadingType = 'absolute' | 'relative';
const LoadingBackground = styled.div<{ type: ModalLoadingType }>`
  ${({ type, theme }) =>
    type === 'absolute'
      ? `
    position: absolute;
    height: 100%;
    background: #000000;
    opacity: 0.5;
    top: 0;
    ${theme.isRtl ? 'right' : 'left'}: 0;
    `
      : `
    min-height: 178px;
  `}

  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  // 모달헤더보다 위로 설정
  z-index: 2;
`;

// intersection observer 트리거하기 위해 1px 사용
const HeaderDimTrigger = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: -1px;
  z-index: -1;
`;
const FooterDimTrigger = styled.div`
  width: 100%;
  height: 1px;
  margin-top: -1px;
  z-index: -1;
`;

export const ModalLoading: FC<{ type?: ModalLoadingType }> = ({
  type = 'relative',
  ...props
}) => (
  <LoadingBackground type={type} {...props}>
    <LoadingIndicator size={18} />
  </LoadingBackground>
);

export interface ModalLayoutProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>,
    ModalProps {
  onClose?: () => void;
  onBack?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAnimationEnd?: () => void;
  mobileType?: MobileModalType;
  isLoading?: boolean;
  navButtonType?: ModalNavButtonType;
  lockScroll?: boolean;
  closeWhenClickOutside?: boolean;
  backIcon?: ReactNode;
  children?: ReactNode;
}
interface ModalContentProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>,
    Pick<ModalLayoutProps, 'lockScroll'> {
  children: ReactNode;
}
export const ModalContent: FC<ModalContentProps> = ({ children, ...props }) => {
  const { setIsBottomReached, setIsTopReached } =
    useContext(ModalContentContext);
  const handleHeaderIntersection = useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      setIsTopReached(entry.isIntersecting);
    },
    [setIsTopReached]
  );
  const handleFooterIntersection = useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      setIsBottomReached(entry.isIntersecting);
    },
    [setIsBottomReached]
  );
  const { observerRef: headerObserverRef } = useIntersection(
    handleHeaderIntersection
  );
  const { observerRef: footerObserverRef } = useIntersection(
    handleFooterIntersection
  );

  return (
    <ModalContentStyle {...props}>
      {/* max-height 주어서 컨텐츠 높이조절 */}
      <HeaderDimTrigger ref={headerObserverRef} />
      {children}
      <FooterDimTrigger ref={footerObserverRef} />
    </ModalContentStyle>
  );
};

const ModalLayout: ForwardRefRenderFunction<
  HTMLDivElement,
  ModalLayoutProps
> = (
  {
    children,
    className,
    onBack,
    onClose,
    isDim = false,
    closeWhenClickOutside = true,
    mobileType = MobileModalType.SCREEN_MODAL,
    navButtonType = ModalNavButtonType.CLOSE,
    lockScroll = true,
    onAnimationEnd,
    ...props
  },
  ref
) => {
  const closeModal = useSetAtom(closeModalAtom);
  const modalType = useContext(ModalKeyContext);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    if (modalType) {
      closeModal(modalType);
    }
  };

  const setOpenModals = useSetAtom(openedModalsAtom);
  useEffect(() => {
    const handleNextLive = (e: KeyboardEvent) => {
      e.stopPropagation();
      if ((e.target as HTMLInputElement)?.value) {
        return;
      }
      if (e.key === 'Escape' || e.code === 'Escape') {
        setOpenModals((prev) => prev.splice(0, prev.length - 1));
        onClose?.();
      }
    };

    window.addEventListener('keyup', handleNextLive, { capture: true });

    return () => {
      window.removeEventListener('keyup', handleNextLive, {
        capture: true,
      });
    };
  }, [onClose, setOpenModals]);

  return (
    <ModalBackground
      isDim={isDim}
      lockScroll={lockScroll}
      onClose={onClose}
      closeWhenClickOutside={closeWhenClickOutside}
      {...props}
    >
      <ModalContentProvider>
        <ModalRootStyle
          ref={ref}
          className={className}
          mobileType={mobileType}
          onAnimationEnd={onAnimationEnd}
        >
          <ModalInner mobileType={mobileType} data-testid='Modal-Inner'>
            {mobileType === MobileModalType.SCREEN_MODAL &&
              navButtonType === ModalNavButtonType.CLOSE && (
                <CloseButton
                  mobileType={mobileType}
                  onClick={handleClose}
                  data-testid='close-modal'
                >
                  <ButtonIcon src='/images/icons/icCloseGray.svg' alt='close' />
                </CloseButton>
              )}
            {navButtonType === ModalNavButtonType.BACK && onBack && (
              <Button onClick={onBack}>
                <ButtonIcon src='/images/icons/icBack.svg' alt='back' />
              </Button>
            )}
            {children}
          </ModalInner>
        </ModalRootStyle>
      </ModalContentProvider>
    </ModalBackground>
  );
};

const ForwardedModalLayout = forwardRef(ModalLayout);
export default ForwardedModalLayout;
export { default as ModalHeader } from './ModalHeader';
export { default as ModalFooter } from './ModalFooter';
