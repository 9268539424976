import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { ModalBackground } from 'src/components/Modal';
import { ModalFooter } from 'src/components/ModalLayout';
import Button from 'src/components/Button';
import { useSetAtom } from 'jotai';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { exitPhoneNumberLoginAtom } from 'src/stores/phoneNumberLogin/atoms';
import {
  AuthErrorDesc,
  AuthErrorImg,
  AuthErrorMain,
  AuthErrorModalLayout,
  AuthErrorTitle,
} from 'src/components/AuthErrorModal/style';
import styled from '@emotion/styled';

const ModalLayout = styled(AuthErrorModalLayout)`
  height: 600px;
`;

const SMSRestrictedModal: FC = () => {
  const { t } = useTranslation();
  const closeModal = useSetAtom(closeModalAtom);
  const exitPhoneNumberLogin = useSetAtom(exitPhoneNumberLoginAtom);

  const onClose = () => {
    closeModal(ModalType.ERROR);
    exitPhoneNumberLogin();
  };

  return (
    <ModalBackground>
      <ModalLayout isDim onClose={onClose}>
        <AuthErrorMain>
          <AuthErrorImg
            src='/images/icons/imgPlaceholder.svg'
            alt='SMS Restricted Error'
          />
          <AuthErrorTitle>{t('sms_reg_restricted_title')}</AuthErrorTitle>
          <AuthErrorDesc>{t('sms_reg_restricted_desc')}</AuthErrorDesc>
        </AuthErrorMain>
        <ModalFooter>
          <Button onClick={onClose} id='COMMON__OK'>
            {t('COMMON__OK')}
          </Button>
        </ModalFooter>
      </ModalLayout>
    </ModalBackground>
  );
};

export default SMSRestrictedModal;
