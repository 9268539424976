import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import jwtDecode from 'jwt-decode';

import {
  addRetryPromiseAtom,
  azarTokenAtom,
  renewAccessTokenAtom,
  handleLoginResultAtom,
  loginWithTokenAtom,
  removeTokenAtom,
} from 'src/stores/auth/atoms';
import { AzarUser } from 'src/types/AzarUser';
import { client, rpcAxios } from 'src/utils/api';
import { ControlledPromise } from 'src/utils/controlled-promise';
import useIsomorphicLayoutEffect from 'src/hooks/useIsomorphicLayoutEffect';

interface Token {
  exp: number;
}

const checkExpire = (exp: number) => {
  const now = new Date().getTime();
  return now > exp * 1000;
};

const retry = async (
  promise: ControlledPromise<void>,
  axiosInstance: AxiosInstance,
  config: AxiosRequestConfig
) => {
  await promise.promise;
  return axiosInstance({ ...config, isRetryRequest: true });
};

export default function useSession() {
  const azarToken = useAtomValue(azarTokenAtom);
  const renewAccessToken = useSetAtom(renewAccessTokenAtom);
  const loginWithToken = useSetAtom(loginWithTokenAtom);
  const addRetryPromise = useSetAtom(addRetryPromiseAtom);
  const handleLoginResult = useSetAtom(handleLoginResultAtom);
  const removeToken = useSetAtom(removeTokenAtom);

  useEffect(() => {
    if (azarToken?.refreshToken) {
      const isRefreshTokenExpired = checkExpire(
        jwtDecode<Token>(azarToken.refreshToken).exp
      );
      if (isRefreshTokenExpired) {
        removeToken();
      }
    }
  }, [azarToken, removeToken]);

  useEffect(() => {
    const rpcInterceptor = rpcAxios.interceptors.response.use(
      async (response) => {
        const isAccessTokenExpired =
          response.data?.error?.data?.throwable?.reason === 'expired_token';
        const isAuthError =
          response.data?.error?.message === 'AuthenticationException';
        if (isAuthError && !response.config.isRetryRequest) {
          const promise = new ControlledPromise<void>();
          addRetryPromise({ promise });
          loginWithToken();
          return retry(promise, rpcAxios, response.config);
        } else if (isAccessTokenExpired && !response.config.isRetryRequest) {
          const promise = new ControlledPromise<void>();
          addRetryPromise({ promise });
          renewAccessToken();
          return retry(promise, rpcAxios, response.config);
        }
        return response;
      },
      async (error) => {
        if (
          error.response?.data === 'Jwt is expired' &&
          !error.config.isRetryRequest
        ) {
          const promise = new ControlledPromise<void>();
          addRetryPromise({ promise });
          renewAccessToken();
          return retry(promise, rpcAxios, error.config);
        } else {
          return Promise.reject(error);
        }
      }
    );
    return () => {
      rpcAxios.interceptors.response.eject(rpcInterceptor);
    };
  }, [addRetryPromise, loginWithToken, renewAccessToken]);

  useEffect(() => {
    const clientInterceptor = client.interceptors.response.use(
      async (response) => response,
      async (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          !error.config.isRetryRequest
        ) {
          const promise = new ControlledPromise<void>();
          addRetryPromise({ promise });
          renewAccessToken();
          return retry(promise, client, error.config);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      client.interceptors.response.eject(clientInterceptor);
    };
  }, [addRetryPromise, renewAccessToken]);

  // TODO: effect가 아닌 다른 방법 개선 고민
  // NOTE: webview login 처리를 위한 로직.
  useIsomorphicLayoutEffect(() => {
    if (window.AzarJs) {
      const loginResponse = window.AzarJs.getLoginResponseJson?.();
      try {
        if (loginResponse) {
          handleLoginResult({
            data: {
              result: JSON.parse(loginResponse) as AzarUser,
              jsonrpc: '2.0',
              id: 0,
            },
          });
        }
      } catch (e) {
        Sentry.captureMessage(
          `Unexpected LoginResponseJson from AzarJs ${e}, userId: ${loginResponse.substr(loginResponse.indexOf('userId'))}, ${loginResponse}`
        );
      }
    }
  }, [handleLoginResult]);
}
